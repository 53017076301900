@import "../../utils/replica-id.scss";

.Benefits {
  background-color: $toranja;
  height: 300vh;
  width: 100%;
  padding-bottom: 0;
  position: relative;
  overflow-y: visible;
  z-index: 6;
}

.BenefitsWrapper {
  top: 0;
  padding-top: 10vh;
  position: sticky;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.VisitCard {
  position: relative;
  height: 55vw;
  width: 55vw;
  z-index: 5;
}

.Card1 {
  position: absolute;
  width: auto;
  height: 75%;

  img {
    position: relative;
    width: auto;
    height: 100%;
  }
}

.Card2 {
  position: absolute;
  width: auto;
  height: 90%;
  top: 15%;
  left: 30%;

  img {
    position: relative;
    width: auto;
    height: 100%;
  }
}

.BenefitsText {
  padding-top: 100px;
  font-family: $main-font;
  font-size: 1.6rem;
  font-weight: 600;
  width: 50vw;
  text-align: right;
  align-self: flex-start;
  padding-right: 100px;
}

.BenefitsTitle {
  margin-bottom: 20px;
}

.BenefitItem {
  white-space: nowrap;
  font-size: 1rem;
  font-weight: normal;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 5px;
  margin-bottom: 5px;
  opacity: 0;
}

.ItemMotion {
  animation: slideFromBottom 1s forwards;
}

.ItemMotion:nth-child(2) {
  animation-delay: 0.2s;
}

.ItemMotion:nth-child(3) {
  animation-delay: 0.4s;
}

.ItemMotion:nth-child(4) {
  animation-delay: 0.6s;
}

.ItemMotion:nth-child(5) {
  animation-delay: 0.8s;
}

.ItemMotion:nth-child(6) {
  animation-delay: 1s;
}

.ItemMotion:nth-child(7) {
  animation-delay: 1.4s;
}

.ArrowIcon {
  width: 0.8em;
  height: auto;

  path {
    stroke: $noir;
  }
}

@keyframes slideFromBottom {
  from {
    transform: translateY(50%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}


@media screen and (max-width: $mobile-breakpoint) {
  .Benefits {
    height: 150vh;
    padding: 0px;
    z-index: 5;
    align-items: start;
  }

  .BenefitsWrapper {
    padding: 0px;
    top: 0px;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    // border: 2px solid white;
  }

  .ArrowIcon {
    min-width: 15px;
  }

  .BenefitsText {
    padding: 0 20px;
    font-size: 5.5vw;
    width: 100%;
    text-align: left;
    margin-top: -50px;
  }

  .BenefitItem {
    white-space: unset;
    font-size: 4vw;
    justify-content: start;
    flex-direction: row-reverse;
    margin-bottom: 10px;
    gap: 10px;

  }

  .VisitCard {
    width: 100%;
    height: 60vh;
    position: relative;
    overflow: hidden;
    margin-left: 0;
  }

  .Card1 {
    width: auto;
    height: 70%;    
  }

  .Card2 {
    width: auto;
    height: 80%;
    top: 15%;
    left: 30%;
  }
}

@media screen and (min-width: $wide-breakpoint) {
  .Benefits {
    padding-right: 10vw;
    min-height: 45vw;
    min-height: 200vh;
  }

  .VisitCard {
    width: 60vw;

    img {
      top: -1vw;
    }
  }

  .BenefitsText {
    padding-top: 10vw;
    font-size: 1.5vw;
    width: 45vw;
  }

  .BenefitsTitle {
    margin-bottom: 1.5vw;
  }

  .BenefitItem {
    font-size: 1.2rem;
    gap: 0.5vw;
    margin-bottom: 0.5vw;
  }

  .ArrowIcon {
    width: 1em;
  }
}