@import "../../utils/replica-id.scss";

.Layer_1,
.Layer_2,
.Layer_3,
.Layer_4 {
    position: absolute;
}

.Layers {
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    z-index: 99;
    position: fixed;
    top: 100vh;
    left: 0;
    animation: mainMotion;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(1,-0.01,.87,.85);
    animation-fill-mode: forwards;
}

.Layer_1,
.Layer_2,
.Layer_3,
.Layer_4 {
    z-index: 99999;
    width: 100%;
    height: 50vh;
}

.motion {
    animation-fill-mode: forwards;
}

.endMotion {
    transition: opacity 0.5s linear;
    opacity: 0;
    z-index: -99999;
}

.Layer_1 {
    animation: Layer1 1s forwards infinite;
    background-color: $toranja;
}

.Layer_2 {
    animation: Layer2 1s forwards infinite;
    background-color: $dark-forest;
}

.Layer_3 {
    animation: Layer3 2s forwards infinite;
    background-color: $noir;
}

.Layer_4 {
    animation: Layer4 2s forwards infinite;
    background-color: $pale-blue;
    height: 100vh;
}

@keyframes mainMotion {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-250%);
    }
}

@keyframes Layer1 {
    0% {
        transform: translateY(0vh);
    }

    50% {
        transform: translateY(1vh);
    }

    100% {
        transform: translateY(0vh);
    }
}

@keyframes Layer2 {
    0% {
        transform: translateY(1vh);
    }

    50% {
        transform: translateY(5vh);
    }

    100% {
        transform: translateY(1vh);
    }
}

@keyframes Layer3 {
    0% {
        transform: translateY(10vh);
    }

    50% {
        transform: translateY(20vh);
    }

    100% {
        transform: translateY(10vh);
    }
}

@keyframes Layer4 {
    0% {
        transform: translateY(35vh);
    }

    50% {
        transform: translateY(40vh);
    }

    100% {
        transform: translateY(35vh);
    }
}