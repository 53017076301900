* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.App,
html,
body {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 15px;
  /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: rgb(169, 196, 202);
  /* Scrollbar track/base color */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(252, 42, 7);;
  /* Scrollbar thumb color */
  border-radius: 5px;
  /* Round edges for a more modern look */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #333333;
  /* Slightly lighter on hover */
}

::-webkit-scrollbar-thumb:active {
  background-color: #1a1a1a;
  /* Slightly darker when active */
}

@media screen and (max-width: 500px) {

  .App,
  html,
  body {
    width: 100vw;
  }
}