@import "../../utils/replica-id.scss";

.PaperCustom {
    // overflow: hidden;
    min-width: 40%;
    border-radius: 10px !important;
    background: linear-gradient(0deg, #E3E8EA 17.64%, #CBD8DC 88.8%);
    border-color: white;
    border: 1.5px solid white !important;
    max-height: 100%;
    overflow-y: auto;
    transition: all 0.5s ease
}

@media screen and (min-width: $wide-breakpoint) {
    .PaperCustom {
        border-width: 2px;
        margin-top: 5vh;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .PaperCustom {
        margin-top: 0;
        width: 100%;
    }
}