@import '../../utils/replica-id.scss';

.ArrowButton {
    background-color: $white;
    color: $secondary-text;
    font-weight: 100;
    padding: 10px 25px;
    padding-right: 8px;
    border-radius: 100px;
    cursor: pointer;
    font-size: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    transition: all 0.2s linear;
    font-family: $main-font, 'sans-serif';
    color: $gray;
    z-index: 1;
    text-align: center;
    text-decoration: none;
}

.Icon {
    border-radius: 100%;
    background-color: $toranja;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
}

.ArrowButton:hover,
.ArrowButton:active {
    background-color: $toranja;
    color: $primary-text;
    transform: scale(0.95);

    .Icon {
        background-color: $white;
        color: $toranja;
    }
}

.bg-blue {
    background-color: $white;

    &:hover {
        background-color: $white;
        color: $toranja;

        .bg-icon-blue {
            background-color: $toranja;
        }

        .Icon {
            color: $white;
        }
    }
}

.bg-icon-blue {
    background-color: $pale-blue;
}

.ArrowButton .bg-blue:hover {
    background-color: $white !important;
}


@media screen and (max-width: $mobile-breakpoint) {
    .ArrowButton {
        padding: 5px 20px;
        padding-right: 4px;
        font-size: 5vw;
        gap: 15px;
    }

    .Icon {
        width: 35px;
        height: 35px;
    }
}


@media screen and (min-width: $wide-breakpoint) {
    .ArrowButton {
        padding: 5px 30px;
        padding-right: 8px;
        font-size: 22px;
    }


    .Icon {
        width: 50px;
        height: 50px;
    }
}