@import "../../utils/replica-id.scss";

.NavbarWrapper .Links {
  display: grid;
}

.Navbar {
  padding: 30px 100px;
  padding-top: 10px;
  background-color: $pale-blue;
}

.NavbarWrapper {
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100px;
  display: grid;
  grid-template-areas: "logo links";
  background-color: $pale-blue;
  justify-content: space-between;
  border-bottom: 1px solid white;
  padding-bottom: 20px;
}

.Logo {
  display: flex;
  width: fit-content;
  font-weight: normal;
  font-size: 12px;
  gap: 10px;
  align-items: end;
  margin-right: auto;
  font-family: $secondary-font;
  font-weight: bold;
  text-transform: uppercase;
  color: $white;
  grid-area: 'logo';

  p {
    width: 200px;
  }
}

.LogoImg path,
.LogoMobile path {
  fill:  $white;
}

.LogoImg,
.LogoMobile {
  position: relative;
  max-height: 50px;
  width: auto;
}

.LangSwitch{
  font-family: $main-font;
  font-size: .8em;
  display: flex;
  gap: .7em;
  cursor: pointer;
  color: $white;
  justify-content: end;
  padding: 10px 0;
  font-weight: normal;

  p{
    opacity: 0.8;
    font-weight: 100;
  }
}

.LangSelected{
  font-weight: 600 !important;
  opacity: 1 !important;
}

.Links {
  gap: 2em;
  grid-area: 'links';
  grid-template-columns: 1fr 1fr;
  width: fit-content;
  grid-template-columns: auto;
}

.Link {
  color: $noir;
  text-decoration: none;
  color: initial;
  font-weight: 600;
  text-transform: uppercase;
  font-family: $main-font, 'sans-serif';
  font-size: 12px;
}

.Link:hover,
.Link-active {
  color: $toranja;
}

.LogoMobile {
  display: none;
}


@media screen and (max-width: $mobile-breakpoint) {
  .Navbar {
    padding: 15px;
    padding-top: 0;
  }

  .NavbarWrapper {
    padding-bottom: 15px;
    max-width: 100vw;
    grid-template-areas: "links";
    align-items: center;
    justify-content: center;
  }

  .LogoMobile {
    height: 7vw;
    width: auto;
  }

  .Logo {
    display: none;
  }

  .LogoMobile {
    display: block;
  }

  .LogoMobile path {
    fill: $white;
  }

  .Links {
    grid-template-columns: auto auto;
    justify-content: space-between;
    width: 90vw;
    align-items: center;
    gap: 0;
  }

  .Link {
    font-size: 1.5vw;
    width: fit-content;
  }
}

@media screen and (min-width: $wide-breakpoint) {
  .Navbar {
    padding: 1.5vw 12vw;
  }

  .Logo {
    p {
      font-size: 0.6vw;
      width: 500px;
    }
  }
}