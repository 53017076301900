@import "../../utils/replica-id.scss";

.About {
  width: 100%;
  height: 300vh;
  position: relative;
  padding: 0 200px;
  background-color: $dark-forest;
}

.AboutWrapper {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px;
  width: fit-content;
  height: fit-content;
  padding-top: 10vh;
}

.AboutText {
  font-family: $main-font, 'sans-serif';
  color: $primary-text;
  font-weight: 500;
  font-size: 2.5vh;
  width: 70%;
  line-height: 1.5em;
}

.SquareContainer {
  display: flex;
  flex-direction: row;
  align-items: start;
  height: fit-content;
  gap: 20px;
}

.SquareImg {
  display: flex;
  width: 35vw;
  height: 35vw;
  align-self: center;
  grid-column: 1 / 2;
  grid-row: 1;
  justify-content: end;
  z-index: 1;
  position: relative;

  .SquareAd {
    height: auto;
    width: auto;
  }
}

.ReplicaIcon {
  width: 22vw;
  height: auto;
  grid-column: 2;
  grid-row: 2;
  position: absolute;
  left: 0px;
  top: -120px;

  .ReplicaIconSVG {
    width: 100%;
    height: auto;
  }
}

.ReplicaLogo {
  height: 40vh;
  width: auto;
}

.SquareBaloon {
  position: absolute;
  width: 200px;
  height: auto;
  left: 0;
  bottom: 20%;
}

@media screen and (max-width: $mobile-breakpoint) {
  .About {
    padding: 50px 20px;
    height: 200vh;
  }

  .AboutWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    top: 0vw;
    padding-top: 5vh;
  }

  .SquareImg {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

    .SquareAd {
      height: auto;
      width: 70%;
    }
  }

  .SquareBaloon {
    position: absolute;
    width: 150px;
    height: auto;
    left: 0px;
    bottom: 20%;
  }

  .AboutText {
    font-family: $main-font, 'sans-serif';
    color: $primary-text;
    font-weight: 500;
    font-size: 4.2vw;
    width: 85%;
    line-height: 1.5em;
  }

  .ReplicaLogo {
    height: 55vw;
    width: auto;
  }

  .ReplicaIcon {
    position: relative;
    left: 0;
    width: 50vw;
    height: auto;
    top: 0;
  }
}

@media screen and (min-width: $wide-breakpoint) {
  .About {
    padding: 5vw 20vw;
    padding-bottom: 20vw;
    height: 300vh;
  }

  .AboutWrapper {
    gap: 0;
    justify-content: center;
  }

  .AboutText {
    font-size: 1.1vw;
  }

  .SquareBaloon {
    position: absolute;
    width: 10vw;
    bottom: 20%;
  }

  .ReplicaLogo {
    height: 15vw;
  }

  .ReplicaIcon {
    width: 20vw;
    left: 0vw;
    top: -3vw;
  }

  .SquareImg {
    width: 28vw;
    height: 28vw;
  }
}