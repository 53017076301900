@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('./fonts/NeueHaasGroteskText-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
 
@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('./fonts/NeueHaasGroteskText-BoldIta.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('./fonts/NeueHaasGroteskText-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('./fonts/NeueHaasGroteskText-MedIta.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('./fonts/NeueHaasGroteskText-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('./fonts/NeueHaasGroteskText-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('./fonts/RobotoMono-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('./fonts/RobotoMono-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('./fonts/RobotoMono-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('./fonts/RobotoMono-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('./fonts/RobotoMono-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('./fonts/RobotoMono-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('./fonts/RobotoMono-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('./fonts/RobotoMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('./fonts/RobotoMono-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
} 