@import "../../utils/replica-id.scss";

.Footer {
  background-color: $toranja;
  height: fit-content;
  width: 100%;
  padding: 50px 100px;
  padding-top: 200px;
  display: flex;
  align-items: start;
  justify-content: end;
  position: sticky;
  flex-direction: column;
  gap: 60px;
  z-index: 5;
  position: relative;
}

.ContactButton {
  background-color: white;
  width: 100%;
  height: fit-content;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $main-font, "sans-serif";
  overflow: hidden;
  padding-right: 40px;
  position: relative;
  color: $noir;
  text-decoration: none;

  .Layer {
    position: absolute;
    width: 30%;
    height: 100%;
    background-image: linear-gradient(0deg, rgb(244, 241, 225) 0%, rgb(169, 196, 203) 100%);
    z-index: 0;
    transition: all 0.2s ease-in;
  }

  &:hover {
    .Layer {
      width: 100%;
    }
  }
}

.Title,
.Email {
  font-size: 25px;
  font-weight: bold;
  z-index: 1;
}

.Title {
  padding: 80px 80px;
  height: 100%;
  white-space: nowrap;
  text-decoration: none;
  
}

.Email {
  padding: 80px 80px;
  text-align: center;

  a {
    color: $noir;
  }
}

.ArrowIcon {
  width: 50px;
  height: 50px;
  background-color: $toranja;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  svg {
    width: 30px;
    height: 30px;
  }

  path {
    stroke: $white;
  }
}

.FooterLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: $main-font, "sans-serif";
  font-size: 14px;
  color: $noir;
  font-weight: 300;

  a {
    color: $noir;
    text-decoration: none;
  }
}

.Ecossystem {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.SocialMedia {
  display: flex;
  gap: 10px;
  font-size: 14px;
  text-decoration: underline;

  a:hover {
    color: $white;
  }
}

.LandscapeLogo {
  width: 200px;
}


@media screen and (max-width: $mobile-breakpoint) {
  .Footer {
    padding: 20px;
    display: flex;
    gap: 20px;
  }

  .ContactButton {
    border-radius: 20px;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    height: 20vw;

    .Layer {
      display: none;
    }

  }

  .Title,
  .Email {
    font-size: 4vw;
  }

  .Title {
    padding: 15px 15px;
    min-height: 100%;
    white-space: unset;
    text-decoration: none;
    background-image: linear-gradient(0deg, rgb(244, 241, 225) 0%, rgb(169, 196, 203) 100%);
    display: flex;
    align-items: center;
  }

  .Email {
    padding: 20px 20px;
  }

  .ArrowIcon {
    display: none;
  }


  .FooterLinks {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .Ecossystem {
    align-items: center;
    gap: 10px;
  }

  .LandscapeLogo {
    width: 150px;
  }

}


@media screen and (min-width: $wide-breakpoint) {
  .Footer {
    padding: 8vw 15vw;
    padding-bottom: 2vw;
    gap: 3vw;
  }
}