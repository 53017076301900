@import "../../utils/replica-id.scss";

.FormatName {
    font-family: $main-font, sans-serif;
    font-size: 1.2em;
    font-style: italic;
    font-weight: 700;
    color: $noir;
    width: 70%;
}

.FormatPrice {
    font-family: $main-font, sans-serif;
    font-size: 1rem;
    color: $noir;
}

.Summary {
    padding-top: 10px !important;
}

@media screen and (min-width: $wide-breakpoint) {
    .Summary {
        padding: 15px 20px !important;
    }

    .FormatName {
        font-size: 1.5rem;
    }

    .FormatPrice {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .Summary {
        padding: 5px 15px !important;
    }

    .FormatName {
        font-size: 1rem;
    }

    .FormatPrice {
        font-size: 1rem;
    }
}