@import "../../utils/replica-id.scss";

.AboutVideo {
  width: 100%;
  background-color: $toranja;
  padding: 0 200px;
  height: 300vh;
  top: 0;
  z-index: 1;
  position: relative;
}

.AboutSticky {
  top: 0;
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.Video {
  width: 100%;
}

.Text {
  font-family: $main-font, 'sans-serif';
  color: $primary-text;
  font-weight: bold;
  font-size: 1.6em;
  text-align: center;
}

@media screen and (max-width: $mobile-breakpoint) {
  .AboutVideo {
    padding: 0px 5px;
    height: 200vh;
  }
  
  .AboutSticky {
    top: 0;
    height: 100vh;
  }

  .Video {
    width: 100%;
  }

  .Text {
    width: 100%;
    font-size: 5.5vw;
  }
}

@media screen and (min-width: $wide-breakpoint) {
  .AboutVideo {
    padding: 0 10vw;
    padding-top: 0;
    height: 300vh;
  }

  .AboutSticky {
    height: 100vh;
  }

  .Video {
    width: 100%;
  }

  .Text {
    width: 100%;
    font-size: 1.5vw;
  }
}