@import '../../utils/replica-id.scss';

.SimpleButton {
    font-weight: 500;
    border-radius: 100px;
    cursor: pointer;
    font-family: $main-font, 'sans-serif';
    font-size: 12px;
    display: flex;
    align-items: center;
    height: fit-content;
    text-decoration: none;
    transition: all 0.2s ease;
    border: 0;

    p {
        text-align: center;
        width: 100%;
    }
}

.fullWidth {
    width: 100% !important;
}

.Contained:hover,
.Contained:active {
    background-color: $noir;
    transform: scale(0.95);
}

.SimpleButton:hover,
.SimpleButton:active {
    transform: scale(0.95);
}

.Contained {
    background-color: $toranja;
    color: $primary-text;
}

.Outlined {
    background-color: transparent;
    color: $toranja;
    border: 1px solid $toranja;
}

.LargeSize {
    padding: 20px 30px;
    min-width: 250px;
    font-size: 16px;
}

.DefaultSize {
    padding: 12px 20px;
    font-size: 12px;
}

.Disabled {
    background-color: #E6E6E6;
    color: #434343;
}

@media screen and (max-width: $mobile-breakpoint) {
    .SimpleButton {
        padding: 5px 15px;
        font-size: 2.5vw;
    }

    .LargeSize {
        padding: 15px 20px;
        width: 100%;
        font-size: 18px;
    }

    .DefaultSize {
        padding: 10px 20px;
        font-size: 12px;
    }
}

@media screen and (min-width: $wide-breakpoint) {
    .SimpleButton {
        padding: 15px 30px;
    }

    .LargeSize {
        padding: 25px 30px;
        min-width: 350px;
        font-size: 18px;
    }

    .DefaultSize {
        font-size: 14px;
    }  
}