@import "../../utils/replica-id.scss";

.CookieBar {
    position: fixed;
    bottom: -100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    padding: 3vh 15px;
    text-align: center;
    z-index: 1000;
    transition: all 1s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $main-font, sans-serif;
    font-size: .8rem;

    a:visited,
    a:active {
      color: $white;
    }
}

.Appear {
    bottom: 0%;
}

.CookieBar button {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 50px;
}

.CookieBar button:hover {
    background-color: #ddd;
}


@media screen and (max-width: $mobile-breakpoint) {
    .CookieBar{
        flex-direction: column;
        gap: 20px;
        font-size: .9rem
    }

    .CookieBar button {
        padding: 10px 20px;
        width: 90%;
    }
    
}