@import "../../utils/replica-id.scss";

.BudgetSection {
    background-color: $pale-blue;
    width: 100%;
    padding: 20px 100px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.HighlightText {
    font-family: $main-font, 'sans-serif';
    font-style: italic;
    font-weight: 300;
    text-decoration: underline;
}

.BudgetText {
    display: flex;
    justify-content: start;
    align-self: start;
    gap: 50px;
    width: 100%;
}

.Title {
    font-family: $main-font, 'sans-serif';
    font-weight: bold;
    width: fit-content;
    width: 50%;
    color: $primary-text;
    font-size: 3em;
    margin: 0;
}

.Instructions {
    font-family: $main-font, 'sans-serif';
    font-weight: 500;
    color: $primary-text;
    font-size: 1.5em;
    margin-top: 10px;

    ol {
        margin-left: 20px;
    }
}

.InstructionsLabel {
    margin-bottom: 10px;
}

.FormatsContainer {
    position: relative;
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    min-height: 100px;
    height: 58vh;
    padding-top: 50px;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: $pale-blue;
    ;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #333333;
}

::-webkit-scrollbar-thumb:active {
    background-color: #1a1a1a;
}

@media screen and (max-width: $mobile-breakpoint) {
    .BudgetSection {
        padding: 0 20px;
        padding-bottom: 0;
        gap: 20px;
        justify-content: space-between;
        height: 90vh;
    }

    .BudgetText {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-self: start;
        gap: 20px;
    }

    .Title {
        font-size: 9vw;
        margin-bottom: 0px;
        width: 100%;
        white-space: wrap;
    }

    .Instructions {
        font-size: 4vw;
    }

    .FormatsContainer {
        bottom: 0;
        flex-direction: column;
        justify-content: end;
        padding-top: 0;
        gap: 0px;
        height: auto;
    }
}

@media screen and (min-width: $wide-breakpoint) {
    .BudgetSection {
        padding: 2vw 12vw;
        gap: 40px;
        height: fit-content;
    }

    .Title {
        font-size: 4.5em;
        width: 55%;
    }

    .Instructions {
        font-size: 1.8em;
        margin-top: 15px;

        ol {
            margin-left: 30px;
        }
    }

    .FormatsContainer {
        align-items: start;
        height: 52vh;
    }
}