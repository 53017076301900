$pale-blue: rgb(169, 196, 202);
$dark-forest: rgb(56, 96, 70);
$toranja: rgb(252, 42, 7);
$noir: rgb(26, 26, 26);
$white: #fffff3;
$gray: #656565;
$primary-text: #fffff3;
$secondary-text: rgb(26, 26, 26);


$main-font:'Neue Haas Grotesk Text';
$secondary-font :'Roboto Mono';

$mobile-breakpoint: 768px;
$tablet-breakpoint: 768px;
$wide-breakpoint: 1500px;
